<template>
  <v-container ref="container">
    <div v-if="!sucessfullyBooked && dialog === false">
      <v-row justify="center">
        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6" class="d-flex justify-center px-5">
          <v-date-picker elevation="1" locale="de" first-day-of-week="1" v-model="selected_date"
            :allowed-dates="allowedDates" :events="allowedDates" event-color="green lighten-1"
            :header-date-format="dateHeader" :title-date-format="dateTitle" no-title :color="primary_color" full-width
            :min="displayMinDate" :max="displayMaxDate" />

        </v-col>
        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6" class="d-flex flex-column">
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn small rounded elevation="0" class="my-2" v-bind="attrs" v-on="on">
                <v-icon left>mdi-filter</v-icon>
                Termin-Filter
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item v-for="(description, index) in availableBookingTypes" :key="index" dense>
                <v-checkbox dense v-model="selectedDescriptions" :value="description" :label="description"></v-checkbox>
              </v-list-item>
            </v-list>
          </v-menu>
          <div v-if="selected_date === null" class="d-flex flex-column align-center text-h6">
            <span>{{ displayInitialText }}</span>
            <v-progress-circular v-if="!loaded_bookings" class="mt-2" indeterminate
              :color="primary_color"></v-progress-circular>
          </div>
          <div v-else>
            <span class="d-flex justify-center text-h6">
              {{ displaySelectedDate }}
            </span>
            <v-list rounded>
              <v-list-item-group :color="primary_color">
                <v-list-item v-for="(item, i) in availableBookingsOnDate" :key="i" @click="selectBooking(item)">
                  <v-list-item-icon>
                    <v-icon>mdi-clock-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.date.format('HH:mm') }} - {{ item.date_end.format('HH:mm') }}: {{ item.description }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-if="!sucessfullyBooked && dialog === true">
      <v-card>
        <v-toolbar dense dark :color="primary_color" elevation="1">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Kontaktdaten</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <span class="text-subtitle-1 py-5 text-justify">
                Bitte geben Sie Ihre Kontaktdaten ein, damit ich Ihren Termin am <b>{{ displaySelectedBooking }} Uhr ({{
      selected_booking_item.description ? selected_booking_item.description : '' }})</b> bestätigen
                kann.<br />
                <b>Wichtig:</b> Erst nachdem ich Ihnen eine Bestätigung per E-Mail oder SMS geschickt habe, ist Ihr
                Termin gültig.
              </span>
            </v-row>
            <v-row class="my-0 py-0">
              <v-col cols="12" sm="6" class="my-0 py-0">
                <v-row class="my-0 py-0">
                  <v-col cols="12" sm="6" class="my-0 py-0">
                    <v-text-field dense outlined v-model="firstname" label="Vorname" required></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" class="my-0 py-0">
                    <v-text-field dense outlined v-model="lastname" label="Nachname" required></v-text-field>
                  </v-col>
                </v-row>
                <v-text-field dense outlined v-model="email" label="E-Mail Adresse" required></v-text-field>
                <v-text-field dense outlined v-model="phone" label="Telefonnummer" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="my-0 py-0">
                <v-textarea dense outlined v-model="message" auto-grow label="Ihre Nachricht" rows="4"
                  row-height="25"></v-textarea>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-end mt-0 pt-0">
              <v-btn text :color="primary_color" @click="dialog = false">
                Abbrechen
              </v-btn>
              <v-btn class="ml-4" :dark="!disableBookingButton" :color="primary_color" @click="confirmBooking()"
                :loading="processing_booking" :disabled="disableBookingButton">
                Abschicken
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
    <div v-if="sucessfullyBooked" class="px-5 d-flex justify-center align-center">
      <v-icon color="green" size="100">mdi-check-circle-outline</v-icon>
      <span class="text-h5 text-center px-5">
        Reservierung erfolgreich abgeschlossen. Ich melde mich bei Ihnen zeitnah bezüglich der Bestätigung Ihres Termins
        am
        <b>{{ displaySelectedBooking }}</b>.
      </span>
    </div>
    <div v-if="errorDuringBooking" class="px-5 d-flex justify-center align-center">
      <v-icon color="red" size="100">mdi-close-circle-outline</v-icon>
      <span class="text-h5 text-center px-5">
        Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie mich direkt per E-Mail oder Telefon.
      </span>
    </div>
    <v-snackbar v-model="snackbar" color="red" timeout="-1">
      Der ausgewählte Termin ist leider nicht mehr verfügbar. Bitte wählen Sie einen anderen Termin.
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          OK!
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { Buffer } from 'buffer'
import { supabase } from '@/supabase'
import dayjs from 'dayjs'
import 'dayjs/locale/de'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: 'Planer',

  data() {
    return {

      selectedDescriptions: [],

      primary_color: null,

      dialog: false,
      selected_date: null,
      loaded_bookings: false,
      bookings: [],
      selected_booking_item: null,
      processing_booking: false,

      available_bookings_on_date: [],
      successfully_booked: false,
      error_during_booking: false,
      error_during_loading: false,
      snackbar: false,

      profile_id: null,
      firstname: null,
      lastname: null,
      email: null,
      phone: null,
      message: null,

      timer: null
    }
  },

  computed: {
    availableBookings() {
      return this.bookings.filter((appointment) => this.selectedDescriptions.includes(appointment.bezeichnung)).map((appointment) => {
        return {
          id: appointment.id,
          date: dayjs.utc(appointment.datum),
          date_end: dayjs.utc(appointment.datum).add(appointment.dauer, 'minutes'),
          dateFormatted: dayjs.utc(appointment.datum).format('YYYY-MM-DD'),
          duration: appointment.dauer,
          description: appointment.bezeichnung,
        }
      })
    },

    availableBookingTypes() {
      // return array of unique descriptions in bookings
      return [...new Set(this.bookings.map((appointment) => appointment.bezeichnung))];
    },

    availableBookingsOnDate() {
      return this.availableBookings
        .filter((booking) => booking.dateFormatted === dayjs(this.selected_date).format('YYYY-MM-DD'))
        .sort((a, b) => dayjs(a.date) - dayjs(b.date));
    },

    displaySelectedBooking() {
      return this.selected_booking_item ? this.selected_booking_item.date.format('DD.MM.YYYY - HH:mm') : ''
    },

    displaySelectedDate() {
      return this.selected_date !== null ? dayjs(this.selected_date).format('DD.MM.YYYY') : ''
    },

    displayInitialText() {
      if (this.loaded_bookings) {
        if (this.bookings.length === 0 && this.error_during_loading === false) {
          return 'Aktuell sind keine Termine online verfügbar. Bitte kontaktieren Sie mich per E-Mail oder Telefon.'
        } else if (this.error_during_loading) {
          return 'Termine konnten nicht geladen werden. Bitte laden Sie die Seite neu oder kontaktieren Sie mich direkt per E-Mail oder Telefon.'
        }
        else {
          return 'Bitte wählen Sie ein Datum aus.'
        }
      } else {
        return 'Termine werden geladen...'
      }
    },

    displayMinDate() {
      return dayjs().startOf('month').format('YYYY-MM-DD')
    },

    displayMaxDate() {
      // get the maximum date in availableBookings
      const max_date = this.availableBookings.reduce((max, booking) => {
        return booking.date.isAfter(max) ? booking.date : max;
      }, dayjs()); // Initialize with the current date

      return max_date.endOf('month').format('YYYY-MM-DD');
    },

    sucessfullyBooked() {
      return this.successfully_booked
    },

    errorDuringBooking() {
      return this.error_during_booking
    },

    disableBookingButton() {
      return this.firstname === null || this.lastname === null || this.email === null || this.phone === null || this.email === '' || this.phone === '' || this.firstname === '' || this.lastname === ''
    }
  },

  mounted() {
    let profile_id = this.$route.query.id
    this.profile_id = profile_id
    this.primary_color = this.$route.query.primary ? '#' + this.$route.query.primary : 'primary'

    this.timer = setInterval(this.iframeHeightNotify, 300)

    this.getBookings(profile_id)

  },

  beforeDestroy() {
    clearInterval(this.timer);
  },

  methods: {

    async getBookings(profile_id) {

      let { data, error } = await supabase.functions.invoke('get-bookings', {
        body: {
          'profileId': profile_id,
          'type': 'get'
        }
      });

      if (error) {
        this.loaded_bookings = true
        this.error_during_loading = true
        return
      }

      this.bookings = data;
      this.selectedDescriptions = [... new Set(this.bookings.map((booking) => booking.bezeichnung))];
      this.loaded_bookings = true;
    },

    selectBooking(item) {
      this.selected_booking_item = Object.assign({}, item)
      this.dialog = true
    },

    async confirmBooking() {
      this.processing_booking = true;

      try {
        // 1. Generate an Encryption Key:
        const key = await window.crypto.subtle.generateKey(
          {
            name: "AES-GCM",
            length: 256,
          },
          true, // extractable
          ["encrypt", "decrypt"]
        );

        // 2. Encrypt the Data:
        const dataToEncrypt = JSON.stringify({
          vorname: this.firstname,
          nachname: this.lastname,
          telefon: this.phone,
          email: this.email,
          message: this.message,
        });

        let iv = window.crypto.getRandomValues(new Uint8Array(16));
        const encryptedData = await window.crypto.subtle.encrypt(
          {
            name: "AES-GCM",
            iv: iv,
          },
          key,
          new TextEncoder().encode(dataToEncrypt)
        );

        // 3. Export the Key for Storage in the Vault:
        const exportedKey = await window.crypto.subtle.exportKey("jwk", key);

        // 4. Convert the key to a JSON string
        const encryptionKey = JSON.stringify(exportedKey);

        // 5. Convert the encrypted data to a base64 string
        const encryptedDataString = Buffer.from(new Uint8Array(encryptedData)).toString('base64');

        // 6. Prepare JSON object for JSONB column
        const jsonbData = JSON.stringify({
          iv: Buffer.from(iv).toString('base64'),
          data: encryptedDataString,
        });

        const { data, error } = await supabase.functions.invoke("get-bookings", {
          body: {
            "profileId": this.profile_id,
            "type": "update",
            "booking": this.selected_booking_item,
            "details": jsonbData,
            "key": encryptionKey,
          },
        });

        // 5. Handle Response:
        if (data && JSON.parse(data).status === "ok") {
          this.successfully_booked = true;
        } else {
          if (data.status === "not available") {
            this.getBookings(this.profile_id);
            this.snackbar = true;
          } else {
            this.error_during_booking = true;
          }
        }
        this.processing_booking = false;
        this.dialog = false;
      } catch (error) {
        console.error("Error during encryption or booking:", error);
        this.error_during_booking = true;
        this.processing_booking = false;
        this.dialog = false;
      }
    },

    allowedDates(date) {
      return this.availableBookings.filter((booking) => booking.dateFormatted === dayjs(date).format('YYYY-MM-DD')).length > 0
    },

    dateHeader(date) {
      return dayjs(date).locale('de').format('MMMM YYYY')
    },

    dateTitle(date) {
      return dayjs(date).locale('de').format('D MMMM YYYY')
    },

    iframeHeightNotify() {
      var msg = {
        height: this.$refs.container.scrollHeight
      }
      window.parent.postMessage(msg, '*')
    }
  },
}
</script>
