<template>
  <planer />
</template>

<script>
  import Planer from '../components/Planer'

  export default {
    name: 'Home',

    components: {
      Planer,
    },
  }
</script>
